<template>
  <div class="page-container">
    <Loader :loading="isLoader" v-if="isLoader" />
    <div v-else class="container">
      <section class="product-report">
        <div class="title flex justify-between">
          <div>{{ title }}</div>
          <div>
            <ExportExcel
              :exportData="reportsData"
              :tableHeaders="exportDataHeaders"
              :infoTableDataRows="infoTableData"
              :fileName="exportFileName"
            />
          </div>
        </div>
        <div class="product__info">
          <div class="filters flex">
            <!--CUC code-->
            <div class="flex flex-row filters__item">
              <label class="label font-bold text-right pr-4 w-1/4"
                >CUC Code:<span class="text-red-600">*</span></label
              >
              <div class="select-box pl-2 relative w-3/4">
                <AutoCompleteDropdown
                  :options="cucCodes"
                  v-model="selectedCucCode"
                  @selected-item="setSelectedCucCode"
                  v-if="isCucCodeRefresh"
                  placeholder="Enter 3 digits of CUC Code"
                  :apiPath="apiPath"
                  isAutoFetch
                  autoFetchType="CucReports"
                /><Loader :loading="isCucLoader" :size="size" />
              </div>
            </div>
            <!-- <div class="filters__item justify-evenly">
              <ExportExcel
                :exportData="reportsData"
                :tableHeaders="exportDataHeaders"
                :infoTableDataRows="infoTableData"
                :fileName="exportFileName"
              />
            </div> -->
          </div>
        </div>
        <!-- View reports for selected cuc code -->
        <div class="view-reports">
          <div class="title">
            {{
              selectedCucCode === ""
                ? ""
                : "Report for Cuc Code " +
                  selectedCucCode.split("|")[0] +
                  ", Revision " +
                  selectedCucCode.split("|")[1]
            }}
          </div>
          <Loader :loading="isLoader" v-if="isLoader" />
          <div class="table-wrapper" v-else>
            <table class="pioti-table table-auto">
              <thead>
                <tr>
                  <th>CUC Code</th>
                  <th>CUC Revision</th>
                  <th>Description</th>
                  <th title="Planned Effective Date">Planned Effective Date</th>
                  <th title="Ingredient Name">Ingredient Name</th>
                  <th title="Inter Spec Name">Inter Spec Name</th>
                  <th>IUPAC Name</th>
                  <th title="EuroPhar Name">EuroPhar Name</th>
                  <!--<th>CAS Number</th>-->
                  <th>EINECS Number</th>
                  <th title="Ingredient Function">Ingredient Function</th>
                  <th>Inclusion Level</th>
                </tr>
              </thead>
              <tbody v-if="reports.length > 0">
                <tr v-for="(row, index) in reports" :key="index">
                  <td>{{ row.CucCode }}</td>
                  <td>{{ row.CucRevision }}</td>
                  <td class="tooltip table-tooltip">
                     <Ellipsis
                      :data="row.CucDescription"
                      :subString="subString"
                      position="right"
                    /></td>
                  <td>
                    {{ row.PlannedEffectiveDate }}
                  </td>
                  <td class="tooltip table-tooltip">
                    <Ellipsis
                      :data="row.IngredientName"
                      :subString="subString"
                      position="left"
                    />
                  </td>
                  <td class="tooltip table-tooltip">
                    <Ellipsis
                      :data="row.InterSpecName"
                      :subString="subString"
                      position="left"
                    />
                  </td>
                  <td class="tooltip table-tooltip">
                    <Ellipsis
                      :data="row.IUPACName"
                      :subString="subString"
                      position="left"
                    />
                  </td>
                  <td class="tooltip table-tooltip">
                    <Ellipsis
                      :data="row.EuroPharName"
                      :subString="subString"
                      position="left"
                    />
                  </td>
                  <!--<td class="tooltip table-tooltip">
                    <Ellipsis
                      :data="row.CASNumber"
                      :subString="subString"
                      position="left"
                    />
                  </td>-->
                  <td class="tooltip table-tooltip">
                    <Ellipsis
                      :data="row.EINECSNumber"
                      :subString="subString"
                      position="left"
                    />
                  </td>
                  <td class="tooltip table-tooltip">
                    <Ellipsis
                      :data="row.IngredientFunction"
                      :subString="subString"
                      position="left"
                    />
                  </td>
                  <td>{{ row.InclusionLevel }}</td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="12">
                    <NoDetailsBox />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import loggerService from "../../services/logger";
import reportService from "../../services/reports";
export default {
  components: {
    Loader: () => import(/* webpackPrefetch: true */ "../common/Loader"),
    AutoCompleteDropdown: () =>
      import(/* webpackPrefetch: true */ "../common/AutoCompleteDropdown"),
    NoDetailsBox: () =>
      import(/* webpackPrefetch: true */ "../common/NoDetailsBox"),
    Ellipsis: () => import(/* webpackPrefetch: true */ "../common/Ellipsis"),
    ExportExcel: () =>
      import(/* webpackPrefetch: true */ "../common/ExportExcel"),
  },
  data: () => ({
    subString: 8,
    exportToExcelData: null,
    exportDataHeaders: [
      {
        CUCCode: "CUCCode",
        CUCRevision: "CUCRevision",
        CucDescription: "CucDescription",
        PlannedEffectiveDate: "PlannedEffectiveDate",
        IngredientName: "IngredientName",
        InterSpecName: "InterSpecName",
        IupacName: "IupacName",
        EuroPharName: "EuroPharName",
        CASNumber: "CASNumber",
        EINECSNumber: "EINECSNumber",
        IngredientFunction: "IngredientFunction",
        InclusionLevel: "InclusionLevel",
      },
    ],
    infoTableData: [],
    exportFileName: "NominalCompositionReport",
    title: "Nominal Composition Report",
    isLoader: true,
    isCucLoader: false,
    size: "5px",
    isCucCodeRefresh: true,
    selectedCucCode: "",
    cucId: "",
    cucCodes: [],
    reports: [],
    reportsData: [],
    isDownloading: false,
    tableDataRows: null,
    header: null,
  }),
  created() {
    setTimeout(() => {
      this.isLoader = false;
    }, 500);
  },
  computed: {
    apiPath() {
      return `FormulationMapping/GetCUCCodesForReport`;
    },
  },
  methods: {
    setSelectedCucCode(cucCode) {
      if (cucCode === true) {
        this.selectedCucCode = "";
        this.reports = [];
        this.reportsData = [];
        return;
      }
      this.selectedCucCode = cucCode.Value;
      this.cucId = cucCode.Id;
      this.fetchReport();
    },
    async fetchReport() {
      try {
        const { data } = await reportService.getNominalCompositionReport(this.selectedCucCode);

        if (data.length === 0 || data === null || data === undefined) {

          loggerService.logError("No data to view/download");

          this.reports = [];
          this.reportsData = [];
          this.isDownloading = false;
        } else {
          this.header = [
            "CUC Code: " + this.selectedCucCode.split("|")[0],
            "Revision: " + this.selectedCucCode.split("|")[1],
            " ",
          ];
          this.infoTableData[0] = {
            CucCode: this.selectedCucCode.split("|")[0],
            Revision: this.selectedCucCode.split("|")[1],
          };
          this.reports = data;
          this.reportsData = data;
          return data;
        }
      } catch (error) {
        loggerService.logError(error);
        this.reports = [];
        this.isDownloading = false;
      }
    },
    isCucCodesRefresh() {
      this.isCucCodeRefresh = false;
      this.$nextTick(() => {
        this.isCucCodeRefresh = true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.product-report {
  table {
    tr {
      th,
      td {
    min-width: 90px;
    max-width: 90px;
        &:nth-child(4) {
          min-width: 130px;
          max-width: 130px;
        }
        &:nth-child(10) {
          min-width: 120px;
          max-width: 120px;
        }
      }
    }
  }
}
.table-wrapper {
  max-height: 170px;
  overflow-y: auto;
}
</style>
